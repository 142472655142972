import React, { useState } from 'react';
import styles from './DownloadLinkList.module.scss';
import classNames from 'classnames';
import Button from '../Button/Button';

const DownloadLinkList = ({ title, downloadsLink, softwaresLink, tabs = [] }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [currentTab, setCurrentTab] = useState(tabs[0]);

    const handleTab = (index) => {
        setActiveTab(index);
        setCurrentTab(tabs[index]);
    };

    return (
        <div className={styles['DownloadLinkList']}>
            <div className={styles['DownloadLinkList__Container']}>
                <h2 className={styles['DownloadLinkList__Title']}>
                    {title}
                </h2>
                <div className={styles['DownloadLinkList__Wrapper']}>
                    <div
                        className={
                            styles['DownloadLinkList__TabContainer']
                        }>
                        {tabs.map((tab, index) => {
                            const tabClasses = classNames(
                                styles['DownloadLinkList__Tab'],
                                {
                                    [styles[
                                        'DownloadLinkList__Tab--Active'
                                    ]]: activeTab === index,
                                }
                            );
                            return (
                                <button
                                    key={index}
                                    className={tabClasses}
                                    onClick={() => {
                                        handleTab(index);
                                    }}>
                                    <h3
                                        className={
                                            styles[
                                            'DownloadLinkList__TabTitle'
                                            ]
                                        }>
                                        {tab.title}
                                    </h3>
                                </button>
                            );
                        })}
                    </div>
                    <div
                        className={
                            styles['DownloadLinkList__ListContainer']
                        }>
                        <ul
                            className={classNames(
                                styles['DownloadLinkList__List']
                            )}>
                            {currentTab?.links.map((link, i) => (
                                <React.Fragment key={i}>
                                    <li
                                        className={
                                            styles[
                                            'DownloadLinkList__Item'
                                            ]
                                        }>

                                        <a
                                            href={link.href}
                                            className={
                                                styles[
                                                'DownloadLinkList__Link'
                                                ]
                                            } dangerouslySetInnerHTML={{__html: link.title + (link.date ? ` <i>(${link.date})</i>`: "")}}>
                                        </a>
                                    </li>
                                </React.Fragment>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={styles['DownloadLinkList__Buttons']}>
                    {softwaresLink && softwaresLink.href && (<Button type="primary" link={softwaresLink} />)}
                    {downloadsLink && downloadsLink.href && (<Button type="primary" link={downloadsLink} />)}
                </div>
            </div>
        </div>
    );
};

DownloadLinkList.propTypes = {};

DownloadLinkList.defaultProps = {};

export default DownloadLinkList;
